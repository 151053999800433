import React from 'react';
import '../Home/Cards.css';
import CardItem from '../Home/CardItem';
import car1 from '../../data/image/aTapiseri/t9.png.jpg'
import car2 from "../../data/image/aTapiseri/t13.png.jpg"
import car3 from "../../data/image/aTapiseri/t11.png.jpg"
import { GiCarSeat ,IoCarSportSharp, BsShopWindow} from "react-icons/all";
import VideoItem from "../Home/videoitem";
function Cards() {
  return (
    <div className='cards'>
      <h1>Abel Tapiseri & Car Decor</h1>
      <h5>Check Out These Incredible Services</h5>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
                icon={ <GiCarSeat />}
              src={car1}
              text='8+ Years Of Experience  '
              label='Luxury'
              path='/service'
            />
            <CardItem
                icon={<IoCarSportSharp  />}
              src={car2}
              text='10,000+ Satisfied Customers'
              label='Luxury'
              path='/service'
            />
              <CardItem
                  icon={<BsShopWindow/>}
                  src={car3}
                  text='Adequate Price Of Materials'
                  label='Luxury'
                  path='/service'
              />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
