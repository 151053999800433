import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import car1 from '../../data/image/car7.jpg'
import car2 from '../../data/image/local1.png'
import car3 from '../../data/image/car2.png'
import { GiCarSeat ,IoCarSportSharp, BsShopWindow} from "react-icons/all";
import shop from '../../data/video/shop.mp4'
import VideoItem from "../Home/videoitem";
function Cards() {
  return (
    <div className='cards'>
      <h1>Abel Tapiseri & Car Decor</h1>
      <h5>Check Out These Incredible Services</h5>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
                icon={ <GiCarSeat />}
              src={car1}
              text='Explore the Conformable , Quality , durable Beautifully Car sit with adequate price '
              label='Luxury'
              path='/service'
            />
            <CardItem
                icon={<IoCarSportSharp  />}
              src={car2}
              text='We Design Interior Beauty Of Cars With Full Quality'
              label='Luxury'
              path='/service'
            />
              <VideoItem
                  src={shop}
                  text='car speaker, pasto polish , car tap , smoking stop car wax , car sit , cleaner , motor flash , car filtrate , car spoiler ... '
                  label='Luxury'
                  path='/service'
              />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
